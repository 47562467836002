header .container-90{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    flex-wrap: wrap;
}

.logo{
    width: 70px;
}

nav{
    font-family: 'Barlow', sans-serif;
    font-size: 17px;
    text-transform: uppercase;
}
 nav a{
     margin-left: 20px;
     text-decoration: none;
     color: black;
 }

 nav a:first-of-type{
    margin-left: 0;
}

 nav a:hover{
     color: #0000C1;
 }

@media (max-width: 450px) {
    nav {
       width: 100%;
       margin-top: 20px;
    }
    nav a{
        margin-left: 20px;
    }
    
}