.project{
    height: 600px;
    background-size: auto 100%!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    padding: 4%;
    border: none;
    border-radius: 8px;
    transition: 0.5s;
}

.project:hover{
    background-size: auto 105%!important;
}

a{
    text-decoration: none;
    color: black;
}

.information{
    background-color: white;
    border-radius: 6px;
    padding: 4%;
    min-height: 25%;
    display: flex;
    justify-content:center;
    flex-direction: column;
}

.information h3{
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
}

@media (max-width: 450px) {
    .project {
      height: 350px;
    }
    .information h3{
        font-size: 35px;
    }
}