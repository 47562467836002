.filter{
    border-radius: 40px;
    background-color: white;
    color: var(--accentBlue);
    border: solid 2px var(--accentBlue);
    display: inline-block;
    margin-right:10px;
    margin-bottom: 10px;
    padding: 2px 25px;
}

.filter.active{
    background-color: var(--accentBlue);
}

.filter:hover{
    background-color: var(--accentBlue);
}