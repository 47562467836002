.mainBanner{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 90vh;
}

h2{
    font-size: 70px;
    font-family: 'Bebas Neue', sans-serif;
}
h3{
    font-family: 'Barlow', sans-serif;
    font-size: 22px;
    font-weight: 400;
}

.darioZalovich{
    display: flex;
    align-items: baseline;
}

.blueNexus{
    background-color: var(--accentBlue);
    height: 10px;
    width: 150px;
    margin: 0 15px;
    transition: 0.5s;
    transition-timing-function: ease-out;
    animation: init 0.8s ease-out;
}
@keyframes init {
    0% { 
        width: 0; 
        }
    100% {
        width: 150px;
        }
}

/*
.blueNexus::after{
    content: "Diseño multimedia";
    position: absolute;
    display: inline-block;
    opacity: 0;
    transition: 0.5s;
    transition-timing-function: ease-out;
    margin-top: 0px;
    width: 150px;
    display: flex;
    justify-content: center;
}

.darioZalovich:hover .blueNexus::after{
  opacity: 1;
  margin-top: 15px;
  width: 250px;
}
*/
.darioZalovich:hover {
   color: black;
}
.darioZalovich:hover .blueNexus{
    width: 250px;
}
#about{
    overflow-x: hidden;
}

.aboutInfo ,.about{
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aboutInfo .data{
    width: 30%;
}

.data .aboutTitle{
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    font-size: 40px;
}

.data .aboutBigWord svg{
   height: 100px;
   margin-top: -10px;
}
.data .aboutBigWord svg text{
    font-size:80px;
    font-weight: 900;
    margin: 0;
}
.nexusCreate{
    padding: 0 0 0 10%;
}
.worlds{
    color: white;
    padding: 0 10%;
}

.worlds .aboutBigWord{
    font-size: 80px;
    font-weight: 900;
    color: white;
    margin: 0;
    margin-top: -20px;
}

.data p{
    font-size: 18px;
    font-weight: 300;
    width: 100%;
}

.aboutInfo .extra{
    width: 60%;
    height: 100%;
}

.strong{
    font-weight: 700;
}

.nexus{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 75%;
}



.nexus img{
    max-width: 35vw;
}


.profile{
    max-width: 40%;
}


@media (max-width: 910px) {
    .aboutInfo .aboutTitle{
        font-size: 25px;
    }
    .data .aboutBigWord svg{
        margin-bottom: -40px;
    }
    .data .aboutBigWord svg text,.worlds .aboutBigWord{
        font-size: 60px;
    }
    .darioZalovich h2{
        font-size: 55px;
    }
    .blueNexus{
        width: 100px;
        height: 7px;
    }
    .darioZalovich:hover .blueNexus{
        width: 150px;
    }
    @keyframes init {
        0% { 
            width: 0; 
            }
        100% {
            width: 100px;
            }
    }
}

@media (max-width: 600px) {
    .aboutInfo {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .aboutInfo .data{
        width: 90%;
    }
    .aboutInfo .extra{
        width: 100%;
        height: 40%;
    }
    .nexusCreate,.worlds{
        padding: 0;
    }
    .nexus img{
        max-width: 60vw;
        margin-top: -60%;
    }
    .profile{
        max-width: 90%;
    }
    .darioZalovich h2{
        font-size: 35px;
    }
    .blueNexus{
        width: 10vw;
        height: 5px;
        margin: 0 10px;
    }
    @keyframes init {
        0% { 
            width: 0; 
            }
        100% {
            width: 10vw;
            }
    }
    .darioZalovich:hover .blueNexus{
        width: 15vw;
    }
    .mainBanner{
        height: 78vh;
    }
}