.loading .blueNexus{
    margin: 0;
    animation: load 3s infinite  linear;
    max-width: 70%;
}

.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes load {
    0% { 
        width: 0; 
        }
        
        50% { 
            width: 100%; 
            }
            
        100% {
            width: 0%;
            }
}