
.back-to-top {
    position: fixed;
    bottom: 3%;
    right: 3%;
    font-size: 25px;
    color: var(--accentBlue);
    height: 50px;
    width: 50px;
    background: gainsboro;
    cursor: pointer;
    border-radius: 100px;
    border: none;
  }
  
  .back-to-top:hover {
    background: var(--accentBlue);
    color: white;
  }