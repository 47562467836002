img{
    max-width: 100%;
}
.sect-one{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-height: 90vh;
}
.sect-one .texts{
    width: 30%;
}

.sect-one h2{
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 62px;
}
.sect-one h4{
    font-size:19px ;
    font-weight: normal;
}
.sect-one img.image {
    width: 35%;
    height: auto;
}

.sect-one .details{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10%;
}
.sect-one .details div{
    margin-right: 10%;
}
.sect-one .details h5,.sect-one .details a{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;    
}
.sect-one .details a{
    text-decoration: underline;
}

.sect-one .details p{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
}
.sect-two{
    display: flex;
    justify-content: space-evenly;
    height: 110vh;
    margin-top: 15vh;
}
.sect-two h3{
    margin-top: 15px;
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 0;
}
.sect-two p{
    padding: 0 10px;
}

.step-card{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    width: 30%;
    max-width: 350px;
}
.step-card-image{
    height: 600px;
    width: 100%;
    border-radius: 15px;
    background-size: cover!important;
    background-position: center!important;
}

.step-card-image img{
    width: 140%;
    height: auto;
}

.sect-three{
}
.sect-three iframe{
    width: 100vw;
    height: calc(100vw*9/16);
    
}

.sect-four{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-height: 80vh;
}

.sect-four .wordls-list{
    margin-left: 10%;
    align-self: center;
}
.sect-four h4{
    font-size: 45px;
    font-family: 'Bebas Neue', sans-serif;
}
.sect-four ul{
    padding: 0;
}
.sect-four li{
    list-style: none;
    font-size: 20px;
    margin-bottom: 25px;
    transition: 0.5s;
}

.sect-four li span::before{
    content: "";
    display: inline-block;
    border: 1px solid var(--accentBlue);
    width: 15px;
    height: 15px;
    border-radius: 25px;
    margin-right: 10px;
}

.sect-four li::before{
    content: "";
    display: inline-block;
    position: absolute;
    z-index: -1;
    margin-top: -25px;
    margin-left: 7px;
    height: 25px;
    width: 1px;
    background-color: var(--accentBlue);
    transition: 0.5s;
}

.sect-four li:first-child:before{
    display: none;
}

.sect-four .wordls-list:hover li{
    margin-bottom: 40px;
}
.sect-four .wordls-list:hover li::before{
    height: 40px;
    margin-top: -40px;
}

.sect-four img{
   max-height:60vh;
}

.gallery{
   display: flex;
   flex-wrap: wrap;
}
.gallery-pic{
    height: 80vh;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}
.gallery-pic:nth-of-type(1){
    width: 50%;
}
.gallery-pic:nth-of-type(2){
    width: 50%;
}
.gallery-pic:nth-of-type(3){
    width: 40%;
}

.gallery-pic:nth-of-type(4){
    width: 60%;
}
.gallery-pic:nth-of-type(5){
    width: 100%;
}
.loadingBig .loading{
padding: 40% 0;
}

@media (max-width: 950px) {
   .sect-one{
       flex-direction: column-reverse;
   }
   .sect-one img.image{
       width: 60%;
       margin-top: 15%;
   }
   .sect-one .texts{
       margin-top: 5%;
       width: 90%;
   }
   .sect-one .details{
       margin-top: 5%;
   }
   .sect-two{
       height: auto;
       flex-direction: column;
       align-items: center;
       margin-bottom: 30%;
   }
   .step-card{
       width: 100%;
       max-width: initial;
   }
   .step-card-image{
    height: 220px;
   }
   .sect-four{
       flex-direction: column-reverse;
       padding: 150px 0;
       align-items:center
   }
   .sect-four .wordls-list{
       margin: 0;
   }
   .gallery-pic{
    height: 35vh;
    }

    .gallery-pic:nth-of-type(1){
        width: 100%;  
    }
    .gallery-pic:nth-of-type(2){
        width: 50%;
        height: 15vh;
    }
    .gallery-pic:nth-of-type(3){
        width: 50%;
        height: 15vh;
    }
    
    .gallery-pic:nth-of-type(4){
        width: 100%;
    }
    .gallery-pic:nth-of-type(5){
        width: 100%;
    }
    

}