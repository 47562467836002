footer{
    color: white;
    padding: 80px 0;
}

footer a{
    color: white;
    display: block;
}
footer p{
    margin: 0;
}
footer .data{
    display: flex;
    justify-content: space-between;
}

footer .data div:last-of-type{
    text-align: right;
}

@media (max-width: 517px) {
    footer .data {
      flex-wrap: wrap;
    }
    footer .data div:last-of-type{
        margin-top: 10px;
        text-align: left;
    }
}