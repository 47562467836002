:root {
    --accentBlue: #0000C1;
    --white: #ffffff;
    --blue:#31317C;
  }
  html{
      overflow-x: hidden;
  }
*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
body{
    max-width: 100%;
    overflow-x: hidden;
    font-family: 'Barlow', sans-serif;
}

.container-90{
    width: 90%;
    margin: 0 auto;
}

.bg-texture-grey{
    background-color: white;
    background-image: url(./img/texture.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode:multiply;
    opacity: 0.2;
}

.bg-texture-blue{
    background-color: var(--blue);
    background-image: url(./img/texture.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode:multiply;
}